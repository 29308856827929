import { defineStore } from 'pinia';

/**
 * Displays popups when needed!
 * Can display a message and/or get a confirmation.
 */

export const usePopupStore = defineStore('popupStore', {
  state: () => {
    return {
      text: '',
			display: false,
      option1: null,
      option2: null,
      timeout: 0,
      promise: () => {}
    };
  },
  actions: {
    message(txt, duration=4000) {
			this.text = txt;
			this.display = true;
      this.option1 = null;
      this.option2 = null;
      this.promise = () => {};

      clearTimeout(this.timeout);

			this.timeout = setTimeout(() => {
				this.display = false;
			}, duration);
		},
    confirm(txt, option1='Confirm', option2='Cancel') {
			this.text = txt;
			this.display = true;
      this.option1 = option1;
      this.option2 = option2;
      
      clearTimeout(this.timeout);

      return new Promise(resolve => {
        this.promise = resolve;
      });
		},
    resolvePromise(ret) {
      this.display = false;
      this.promise(ret);
    }
  }
});