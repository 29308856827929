import { defineStore } from 'pinia';

/**
 * Just holds all the different permissions for pages
 * and gives whether a user with a certain permissionLevel would have access to a page.
 */

export const usePermissionStore = defineStore('permissionStore', {
  state: () => {
    return {
			permissionLevels: [
				// Hidden means it is internal and not shown as an option for users
				{ Level: 0, Name: 'ALL', HumanName: 'All', Description: '', hidden: 1 },
				{ Level: 1, Name: 'CLIENT', HumanName: 'Client', Description: '', hidden: 1 },
				{ Level: 2, Name: 'USER', HumanName: 'Basic', Description: '', hidden: 0 },
				{ Level: 3, Name: 'TECH', HumanName: 'Advanced', Description: '', hidden: 0 },
				{ Level: 4, Name: 'ADMIN', HumanName: 'Super Admin', Description: '', hidden: 0 }
			],
      pagePermissions: {
				"Dashboard": 2,          // Sees Dashboard tab
				"ChangePassword": 2,     // Can go to change password page or change their password in the backend
				"Login": 0,              // Login page, anyone, even not logged in, can access
				"Error404": 0,           // A 404 page, anyone, even not logged in, can access
				"ViewLicense": 4,        // Sees License tab
				"ManageAttune": 4,        // Sees Manage Attune (DP) tab
				"Agent": 3,              // Sees Agent tab
				"Deployments": 2,        // Sees Deployments tab
				"Health": 2,             // Sees Assets tab  (old name Health)
				"Asset": 2,              // Can view an individual Computer
				"Collections": 2,        // Sees Collections Tab
				"Config": 3,             // Sees Configuration Tab
				"GlobalConfig": 4,       // Can edit and apply Global Rules
				"Packages": 2,           // Sees Software tab
				"AdminGroups": 4,        // Sees Groups Tab
				"AdminAccounts": 4,		   // Sees Accounts Tab
				"AdminOrganizations": 3, // Sees Organization Tab
				"CreateOrganizations": 4 // Can create new Organizations
			}
    };
  },
  actions: {
    permissionLevel(name) {
			return this.permissionLevels.find(pLevel => pLevel.Name === name) ?? null;
		},
    permissionLevelFromLevel(level) {
			return this.permissionLevels.find(pLevel => pLevel.Level === level) ?? null;
		},
		comparePagePermission(page, level) {
			let pageP = this.pagePermissions[page];

			if(pageP === undefined) return false;

			return level >= pageP;
		}
  },
	getters: {
		notHiddenPermissionLevels() {
			return this.permissionLevels.filter(pLevel => !pLevel.hidden);
		}
	}
});