import { defineStore } from 'pinia';

import { CURRENT_BASE_URL } from '@/composables/constants';

/**
 * Stores the user information across pages.
 * Upon logging in or resuming a session, the account information returned by pagePermission.php is stored.
 * Also in charge of Organization permissions in the UI,
 * and preferences for things like if the sidebar is expanded by default.
 */

export const useUserStore = defineStore('userStore', {
  state: () => {
    let defaultPreferences = {
      expandedCollectionOrgs: [],
      appSidebarVisible: true,
      addMenuOpen: null
    };

    let preferences = { ...defaultPreferences, ...JSON.parse(localStorage.getItem("preferences") ?? '{}') };

    for (const key in preferences) {
      if(!(key in defaultPreferences)) {
        delete preferences[key];
      }
    }

    localStorage.setItem('preferences', JSON.stringify(preferences));

    return {
      loading: false,
      showLoader: false,
      loggedIn: false,
      accountID: null,
      name: '',
      sessionId: '',
      token: '',
      permissions: {},
      permissionLevel: 2,
      groups: [],
      organizations: [],
      preferences,
      license: {
        FeatureID: 0
      }
    };
  },
  actions: {
    startLoading() {
      this.loading = true;
      setTimeout(() => {
        if(this.loading) {
          this.showLoader = true;
        }
      }, 1000);
    },
    endLoading() {
      this.loading = false;
      this.showLoader = false;
    },
    login(account, token) {
      this.loggedIn = true;
      this.token = token;
      this.accountID = account.AccountID;
      this.name = account.AccountName;
      this.sessionId = account.SessionID;
      this.permissions = account.Permissions;
      this.permissionLevel = account.PermissionLevel;
      this.groups = account.Groups;
      this.organizations = account.Organizations;
      this.license = account.License;

      localStorage.setItem('token', token);
      
    },
    logout() {
      this.loggedIn = false;
      this.token = '';
      this.accountID = null;
      this.name = '';
      this.sessionId = '';
      this.permissions = {};
      this.permissionLevel = 2;
      this.groups = [];
      this.organizations = [];
      this.license = {
        FeatureID: 0
      };

      localStorage.removeItem('token');
    },
    saveRememberMeToken(rmToken) {
      localStorage.setItem('rm_token', rmToken);
    },
    getRememberMeToken() {
      return localStorage.getItem('rm_token');
    },
    removeRememberMeToken() {
      localStorage.removeItem('rm_token');
    },
    async reloadUserData() {
      if(this.accountID) {
        let result = await fetch(`
          ${CURRENT_BASE_URL}/sql/Account/Accounts.php?AccountID=${this.accountID}&_token=${this.token}
        `);
        let data = await result.json();

        if(result.ok && data) {
          this.name = data.AccountName;
          this.sessionId = data.SessionID;
          this.permissions = data.Permissions;
          this.permissionLevel = data.PermissionLevel;
          this.groups = data.Groups;
          this.organizations = data.Organizations;
        }
      }
    },
    async reloadLicenseStatus() {
      let result = await fetch(`
        ${CURRENT_BASE_URL}/sql/Account/Accounts.php?_token=${this.token}
      `);
      let data = await result.json();

      if(result.ok && data) {
        this.license = data;
      }
    },
    getOrganizationsByBit(requiredBit) {
      return this.organizations.filter(org => (((+org.BitMask) & this.license.FeatureID) >>> requiredBit) % 2 === 1);
    },
    checkOrgAccess(OrganizationID, requiredBit) {
      let organization = this.organizations.find(org => org.OrganizationID == OrganizationID);

      if(organization === undefined) return false;

      return (((+organization.BitMask) & this.license.FeatureID) >>> requiredBit) % 2 === 1;
    },
    getPreference(name) {
      return this.preferences[name];
    },
    savePreference(name, value) {
      this.preferences[name] = value;
      
      localStorage.setItem('preferences', JSON.stringify(this.preferences));
    }
  }
});