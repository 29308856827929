import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/stores/userStore';
import { usePopupStore } from '@/stores/popupStore';
import { useConfirmSaveStore } from '@/stores/confirmSaveStore';
import { BASE_URL, CURRENT_BASE_URL, PAGE_PERMISSION_LEVELS } from '@/composables/constants';

const routes = [
  {
    page: '/',
    redirect: { name: 'Dashboard' }
  },
  {
    path: '/agent',
    name: 'Agent',
    component: () => import(/* webpackChunkName: "Agent" */ '@/views/Agent.vue')
  },
  {
    path: '/settings/license',
    name: 'ViewLicense',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/ViewLicense.vue')
  },
  {
    path: '/settings/managedps',
    name: 'ManageAttune',
    component: () => import(/* webpackChunkName: "Settings" */ '@/views/ManageAttune.vue')
  },
  { path: '/license', redirect: { name: 'ViewLicense' }},
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Base" */ '@/views/Login.vue')
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: () => import(/* webpackChunkName: "Base" */ '@/views/Accounts/ChangePassword.vue')
  },
  {
    path: '/deployments',
    name: 'Deployments',
    component: () => import(/* webpackChunkName: "Deployments" */ '@/views/Deployments.vue')
  },
  {
    path: '/assets',
    name: 'Health',
    component: () => import(/* webpackChunkName: "Health" */ '@/views/Health.vue')
  },
  {
    path: '/assets/:SN',
    name: 'Asset',
    props: true,
    component: () => import(/* webpackChunkName: "Health" */ '@/views/Assets/Computer.vue')
  },
  {
    path: '/rules',
    name: 'Config',
    component: () => import(/* webpackChunkName: "Config" */ '@/views/Config.vue')
  },
  { path: '/config', redirect: { name: 'Config' }},
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "Base" */ '@/views/Dashboard.vue')
  },
  {
    path: '/collections',
    name: 'Collections',
    component: () => import(/* webpackChunkName: "Collections" */ '@/views/Collections.vue')
  },
  {
    path: '/packages',
    name: 'Packages',
    component: () => import(/* webpackChunkName: "Packages" */ '@/views/Packages.vue')
  },
  {
    path: '/admin/groups',
    name: 'AdminGroups',
    component: () => import(/* webpackChunkName: "Admin" */ '@/views/Admin/Groups.vue')
  },
  {
    path: '/admin/accounts',
    name: 'AdminAccounts',
    component: () => import(/* webpackChunkName: "Admin" */ '@/views/Admin/Accounts.vue')
  },
  {
    path: '/organizations',
    name: 'AdminOrganizations',
    component: () => import(/* webpackChunkName: "Admin" */ '@/views/Admin/Organizations.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'Error404',
    component: () => import(/* webpackChunkName: "Base" */ '@/views/Error404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(BASE_URL),
  routes
});


/**
 * Runs before any page can be loaded. 
 * Fetches `pagePermission.php` then either returns true to continue navigation or false or a reroute to deny access.
 */
router.beforeEach(async (to, from) => {
  let userStore = useUserStore();
  let popupStore = usePopupStore();
  let confirmSaveStore = useConfirmSaveStore();

  if(!(await confirmSaveStore.confirmChanges())) {
    return false;
  }

  // Only check permissions when changing pages
  // Not when changing query or something
  if(from.name === to.name) {
    return true;
  }

  let token = '';

  if(userStore.loggedIn) {
    token = userStore.token;
  } else {
    let storageToken = localStorage.getItem('token');
    if(storageToken) {
      token = storageToken;
    }
  }

  let url = new URL(to.href, window.location.origin).href;

  let result;
  let data;
  
  userStore.startLoading();

  try {
    result = await fetch(`
      ${CURRENT_BASE_URL}/sql/auth/pagePermission.php?
      token=${encodeURIComponent(token)}&
      page=${to.name}&
      url=${encodeURIComponent(url)}
    `);
    data = await result.json();
  } catch(e) {
    userStore.endLoading();
      
    let want = await popupStore.confirm('There was an error authenticating your token (10).<br>Would you like to log out and try again?');

    if(want) {
      userStore.logout();

      if(to.name !== 'Login')
        return { name: 'Login' };
    }
    return false;

  } finally {
    userStore.endLoading();
  }

  
  if(data.allowed) {

    if(data.account) {
      userStore.login(data.account, token);
    }

    if(userStore.loggedIn && to.name === 'Login') {
      return { name: 'Dashboard' };
    }
    
    return true;

  } else {

    if(data.reason === 'expired_token') {
      popupStore.message('Your session has expired. Please login to continue.');
      
      userStore.logout();
      return { name: 'Login' };
    }
    else if(data.reason === 'invalid_token' || data.reason === 'bad_token' || data.reason === 'no_account') {
      popupStore.message('Your token was not valid. Please login to continue.');
      
      userStore.logout();
      return { name: 'Login' };
    }
    else if(data.reason === 'no_token') {
      popupStore.message('You must sign in to access this.');
      
      userStore.logout();
      return { name: 'Login' };
    }
    else if(data.reason === 'permission_denied') {
      if(data.account && !userStore.loggedIn) {
        userStore.login(data.account, token);
      }

      popupStore.message('You do not have permission to see this page.');

      return false;
    }
    else {
      let want = await popupStore.confirm('There was an error authenticating your token (20).<br>Would you like to log out and try again?');

      if(want) {
        userStore.logout();

        if(to.name !== 'Login')
          return { name: 'Login' };
      }
      return false;
    }
  }
})

export default router
