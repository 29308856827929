import { defineStore } from 'pinia';

import { usePopupStore } from './popupStore';

/**
 * Currently only used on the Admin pages to warn a user when
 * they're about to switch pages while editing an Account, Group, or Organization.
 */

export const useConfirmSaveStore = defineStore('confirmSaveStore', {
  state: () => {
    return {
			popupStore: usePopupStore(),
      unsavedChanges: false
    };
  },
  actions: {
    changesMade() {
			this.unsavedChanges = true;
		},
		changesSaved() {
			this.unsavedChanges = false;
		},
		async confirmChanges() {
			if(this.unsavedChanges) {
				let confirm = await this.popupStore.confirm('You have unsaved changes! Would you like to continue?');
				
				if(confirm) this.unsavedChanges = false;

				return confirm;
			} else {
				return true;
			}
		}
  }
});