<template>
  <div class="flex-grow flex flex-row font-SwimageSans tracking-wide">
    <div class="sticky flex-shrink-0 left-0 inset-y-0 z-30 flex flex-col items-stretch h-screen bg-gray-600 shadow-lg swimage-sidebar" v-if="route.name !== 'Login'" :style="{ width: sidebarWidth + 'px' }">
      <!-- <- Button -->
      <button 
        :class="['absolute top-2 right-2 z-50 w-8 h-8 rounded sidebar-button', sidebarVisible ? 'sidebar-button-open' : 'sidebar-button-closed']" 
        @click="toggleSidebar"
      >
        <Transition name="fade">
          <i class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fa-solid fa-bars" v-if="!sidebarVisible"></i>
          <i class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 fa-solid fa-arrow-left" v-else></i>
        </Transition>
      </button>
      <!-- Swimage Fish -->
      <div class="overflow-hidden">
        <div class="block flex-shrink-0 p-2 w-[196px]">
          <img src="@/assets/Swimage-Web-Header.png" class="block" />
        </div>
      </div>
      <div class="mx-2 mb-2 border border-swimage-600"></div>
      <div class="flex flex-col items-center w-full overflow-hidden">
        <!-- Warnings -->
        <div class="py-2 mb-2 bg-red-500 text-white text-center font-semibold" style="width: 260px;" v-if="userStore.license.Expired == true">
          <span class="transition-opacity duration-300" :style="{ opacity: sidebarVisible ? 1 : 0 }">Your License is expired.</span>
        </div>
        <div class="py-4 mb-2 bg-amber-500 text-white text-center" style="width: 260px;" v-else-if="daysUntilLicenseExpires <= 30">
          <span class="transition-opacity duration-300" :style="{ opacity: sidebarVisible ? 1 : 0 }">License expires in <span class="font-semibold">{{ daysUntilLicenseExpires }} days</span>.</span>
        </div>
        <div class="py-2 mb-2 bg-amber-500 text-white text-center" style="width: 260px;" v-if="lowNodes">
          <span class="transition-opacity duration-300" :style="{ opacity: sidebarVisible ? 1 : 0 }">License has <span class="font-semibold">{{ Math.max(0, userStore.license?.AvailableNodes - userStore.license?.ActiveNodes) }} {{ (userStore.license?.FeatureID >>> 14) % 2 === 1 ? 'Nodes' : 'Deployments'}}</span> remaining.</span>
        </div>
      </div>
      <!-- Links -->
      <template v-if="userStore.loggedIn">
        <router-link class="header-link" :to="{ name: 'Dashboard' }">
          <div class="header-icon"><i class="fa-solid fa-chart-line"></i></div>
          <Transition name="fade"><div v-show="sidebarVisible" class="header-title">Dashboard</div></Transition>
        </router-link>
        <router-link class="header-link" :to="{ name: 'Deployments' }" v-if="showLink('Deployments')">
          <div class="header-icon"><i class="fa-solid fa-bars-progress"></i></div>
          <Transition name="fade"><div v-show="sidebarVisible" class="header-title">Deployments</div></Transition>
        </router-link>
        <router-link class="header-link" :to="{ name: 'Health' }" v-if="showLink('Health')">
          <div class="header-icon"><i class="fa-solid fa-computer"></i></div>
          <Transition name="fade"><div v-show="sidebarVisible" class="header-title">Assets</div></Transition>
        </router-link>
        <router-link class="header-link" :to="{ name: 'Collections' }" v-if="showLink('Collections')">
          <div class="header-icon"><i class="fa-solid fa-table-list"></i></div>
          <Transition name="fade"><div v-show="sidebarVisible" class="header-title">Collections</div></Transition>
        </router-link>
        <router-link class="header-link" :to="{ name: 'Config' }" v-if="showLink('Config')">
          <div class="header-icon"><i class="fa-solid fa-bolt"></i></div>
          <Transition name="fade"><div v-show="sidebarVisible" class="header-title">Rules</div></Transition>
        </router-link>
        <router-link class="header-link" :to="{ name: 'Packages' }" v-if="showLink('Packages')">
          <div class="header-icon"><i class="fa-solid fa-file-import"></i></div>
          <Transition name="fade"><div v-show="sidebarVisible" class="header-title">Software</div></Transition>
        </router-link>
        <router-link class="header-link" :to="{ name: 'AdminOrganizations' }" v-if="showLink('AdminOrganizations')">
          <div class="header-icon"><i class="fa-solid fa-users-rectangle"></i></div>
          <Transition name="fade"><div v-show="sidebarVisible" class="header-title">Organizations</div></Transition>
        </router-link>
        <div class="header-link dropdown-toggle-link" @click="toggleMenu('Admin')" v-if="showAdminDropdown" :showCaret="sidebarVisible ? 'yes' : 'no'" :toggled="(menuOpen === 'Admin' ? 'open' : 'closed')">
          <div class="header-icon"><i class="fa-solid fa-users"></i></div>
          <Transition name="fade"><div v-show="sidebarVisible" class="header-title">Admin</div></Transition>
        </div>
        <CCollapse :visible="menuOpen === 'Admin'">
          <div class="header-dropdown">
            <router-link class="header-link dropdown-link header-title" :to="{ name: 'AdminGroups' }" v-if="showLink('AdminGroups')">Groups</router-link>
            <router-link class="header-link dropdown-link header-title" :to="{ name: 'AdminAccounts' }" v-if="showLink('AdminAccounts')">Accounts</router-link>
            <router-link class="header-link dropdown-link header-title" :to="{ name: 'Agent' }" v-if="showLink('Agent')">Agent Download</router-link>
          </div>
        </CCollapse>
        <div class="header-link dropdown-toggle-link" @click="toggleMenu('Settings')" v-if="showSettingsDropdown" :showCaret="sidebarVisible ? 'yes' : 'no'" :toggled="(menuOpen === 'Settings' ? 'open' : 'closed')">
          <div class="header-icon"><i class="fa-solid fa-gears"></i></div>
          <Transition name="fade"><div v-show="sidebarVisible" class="header-title">Settings</div></Transition>
        </div>
        <CCollapse :visible="menuOpen === 'Settings'">
          <div class="header-dropdown">
            <router-link class="header-link dropdown-link header-title" :to="{ name: 'ViewLicense' }" v-if="showLink('ViewLicense')">License</router-link>
            <router-link class="header-link dropdown-link header-title" :to="{ name: 'ManageAttune' }" v-if="showLink('ManageAttune')">Manage DPs</router-link>
          </div>
        </CCollapse>
        <div class="flex-grow"></div>
        <CDropdown variant="input-group" placement="top-end">
          <CDropdownToggle class="header-link cursor-pointer border-none rounded-none px-0" :caret="false">
            <div class="header-icon"><i class="fa-solid fa-user"></i></div>
            <Transition name="fade"><div v-show="sidebarVisible">{{ userStore.name }}</div></Transition>
          </CDropdownToggle>
          <CDropdownMenu style="min-width: 180px; max-width: 240px;">
            <div class="flex flex-col gap-3 px-4 py-2">
              <div class="flex flex-row items-center justify-between gap-3 overflow-hidden">
                <div class="font-medium">Scope</div>
                <div class="overflow-hidden break-words">
                  {{ permissionStore.permissionLevelFromLevel(userStore.permissionLevel).HumanName }}
                </div>
              </div>
              <div class="flex flex-row items-center justify-between gap-3 overflow-hidden">
                <div class="font-medium">Group</div>
                <div class="overflow-hidden break-words" v-if="userStore.groups.length > 0">
                  {{ userStore.groups.map(group => group.GroupName).join(', ') }}
                </div>
                <div class="text-gray-400" v-else>
                  No Group
                </div>
              </div>
            </div>
            <CDropdownDivider/>
            <CDropdownItem component="button" @click="changePassword">Change Password</CDropdownItem>
            <CDropdownDivider/>
            <CDropdownItem component="button" @click="logout">Sign out</CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </template>
      <template v-else>
        <div class="flex-grow"></div>
        <router-link class="header-link block text-center login" :to="{ name: 'Login' }">Sign in</router-link>
      </template>
    </div>
    <div class="relative flex-grow flex flex-col overflow-hidden">
      <router-view />
      <Transition name="fade">
        <div style="box-shadow: 0px 0px 4px 4px white;" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-row items-center justify-center gap-6 w-96 h-48 bg-white text-gray-400 z-50" v-if="userStore.showLoader">
          <div class="text-2xl">Loading</div>
          <CSpinner/>
        </div>
      </Transition>
    </div>
    <Transition name="slide-from-top">
      <div class="top-popup fixed top-2 left-1/2 min-w-[250px] max-w-[400px] p-4 bg-swimage-800 shadow-xl text-white" v-if="popupStore.display">
        <div v-html="popupStore.text"></div>
        <div class="flex flex-row justify-around pt-3 mt-3 border-t border-swimage-900" v-if="popupStore.option1 !== null || popupStore.option2 !== null">
          <CButton color="white" class="w-24" size="sm" v-if="popupStore.option1 !== null" @click="popupStore.resolvePromise(true)">{{ popupStore.option1 }}</CButton>
          <CButton 
            class="w-24 bg-transparent text-white hover:bg-white hover:text-black border-white" 
            size="sm" 
            v-if="popupStore.option2 !== null"
            @click="popupStore.resolvePromise(false)"
          >{{ popupStore.option2 }}</CButton>
        </div>
      </div>
    </Transition>
  </div>
</template>
<script setup>
import router from '@/router';
import { ref, computed, watch, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { CSidebar, CCollapse, CDropdown, CDropdownToggle, CDropdownMenu, CDropdownItem, CDropdownDivider, CSpinner, CButton } from '@coreui/vue';
import { useUserStore } from '@/stores/userStore';
import { usePopupStore } from '@/stores/popupStore';
import { usePermissionStore } from '@/stores/permissionStore';

let userStore = useUserStore();
let popupStore = usePopupStore();
let permissionStore = usePermissionStore();

let route = useRoute();

function treatAsUTC(date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

function daysBetween(startDate, endDate) {
    return Math.floor((treatAsUTC(endDate) - treatAsUTC(startDate)) / (24 * 60 * 60 * 1000));
}

let daysUntilLicenseExpires = computed(() => userStore.license.Expiration === null ? 99999 : Math.max(0, daysBetween(new Date(), userStore.license.Expiration)));

// If less than 10% of nodes left
let lowNodes = computed(() => (userStore.license?.AvailableNodes - userStore.license?.ActiveNodes) / userStore.license?.AvailableNodes < 0.1 && userStore.license?.AvailableNodes !== null && userStore.license?.ActiveNodes !== null);

function showLink(name) {
  return permissionStore.comparePagePermission(name, userStore.permissionLevel);
}

function logout() {
  userStore.logout();
  router.push({ name: 'Login' });
}

function changePassword() {
  router.push({ name: 'ChangePassword' });
}

let sidebarVisible = ref(true);
let sidebarWidth = computed(() => sidebarVisible.value ? 260 : 65);

function toggleSidebar() {
  sidebarVisible.value = !sidebarVisible.value;
  userStore.savePreference('appSidebarVisible', sidebarVisible.value);

  if(sidebarVisible.value === false) {
    menuOpen.value = null;
    
    userStore.savePreference('appMenuOpen', menuOpen.value);
  }
}

let menuOpen = ref(null);

function toggleMenu(menu) {
  let current = menuOpen.value;
  
  if(current == null) {
    sidebarVisible.value = true;
  }

  if(current == menu) {
    menuOpen.value = null;
  } else {
    menuOpen.value = menu;
  }

  userStore.savePreference('appMenuOpen', menuOpen.value);
}

let AdminList = ['AdminGroups', 'AdminAccounts', 'Agent'];
let SettingsList = ['ViewLicense', 'ManageAttune'];

watch(route, () => {
  if(AdminList.includes(route.name)) {
    menuOpen.value = 'Admin';

    userStore.savePreference('appMenuOpen', menuOpen.value);
  } else if(SettingsList.includes(route.name)) {
    menuOpen.value = 'Settings';

    userStore.savePreference('appMenuOpen', menuOpen.value);
  }
});

onMounted(() => {
  sidebarVisible.value = userStore.getPreference('appSidebarVisible') ?? true;
  menuOpen.value = userStore.getPreference('appMenuOpen') ?? null;
});

let showAdminDropdown = computed(() => (showLink('AdminGroups') || showLink('AdminAccounts') || showLink('Agent')));
let showSettingsDropdown = computed(() => (showLink('ViewLicense') || showLink('ManageAttune')));

</script>
<style>
body {
  @apply flex flex-col min-h-screen;
}
#app {
  @apply flex-grow flex flex-col text-gray-800;
}

#app .header-link {
  @apply py-2 flex flex-row items-center text-white hover:bg-white/5 text-lg font-semibold no-underline overflow-hidden cursor-pointer;
}
#app .header-link.router-link-active:not(.login) {
  @apply bg-swimage-500/50 !important;
}

#app .header-title {
  white-space: nowrap;
}

#app .header-icon {
  @apply min-w-[65px] text-lg text-center;
}

.swimage-sidebar {
  transition: width 0.5s;
}

.sidebar-button {
  transition: transform 0.5s, color 0.5s, opacity 0.2s;
}

.sidebar-button-open {
  transform: translateX(0px);
  
  @apply text-white hover:bg-white/10;
}
.sidebar-button-closed {
  transform: translateX(48px);
  
  @apply text-black opacity-30 hover:opacity-100 hover:bg-white/30;
}

#app .header-dropdown {
  @apply bg-gray-700
}

#app .dropdown-link {
  @apply pl-[65px]
}

#app .dropdown-toggle-link:before {
  font: var(--fa-font-solid);
  content: "\f078";
  font-size: 14px;

  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(0deg);

  transition: transform 0.3s ease, opacity 0.3s ease;
}

#app .dropdown-toggle-link[toggled=open]:before {
  transform: translateY(-50%) rotate(180deg);
}

#app .dropdown-toggle-link[showCaret=no]:before {
  opacity: 0;
}

*, ::after, ::before {
  position: relative;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.top-popup {
  transform: translate(-50%, 0px);

  z-index: 1001;
}

.slide-from-top-enter-active,
.slide-from-top-leave-active {
	transition: transform 0.2s, opacity 0.2s;
}

.slide-from-top-enter-to, .slide-from-top-leave-from {
  transform: translate(-50%, 0px);
	opacity: 1;
}

.slide-from-top-leave-to, .slide-from-top-enter-from {
  transform: translate(-50%, -20px);
	opacity: 0;
}
  
</style>
