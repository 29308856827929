
export const BASE_URL = "/Portal";
export const DEV_BASE_URL = localStorage.getItem('dev_url') ?? "http://portal152.swimage.com/Portal";

export const CURRENT_BASE_URL = process.env.NODE_ENV === 'development' ? DEV_BASE_URL : BASE_URL;

export const PAGE_LIST = [
	"Agent",
	"Deployments",
	"Health",
	"Collections",
	"Config",
	"Packages",
	"AdminGroups",
	"AdminAccounts",
	"AdminOrganizations",
	"AdminOrganizationComputers"
];

/**
 * The order in the list is the order they appear on the portal.
 * bit is the actual bit number in the Bitmask
 */
export const ORGANIZATION_ACCESS_BITS = [
	{
		name: "View Computers",
		bit: 0
	},
	{
		name: "View Collections",
		bit: 1
	},
	{
		name: "Apply Actions to Computers",
		bit: 2
	},
	{
		name: "Assign Rules to Collections",
		bit: 3
	},
	{
		name: "Create/Edit Rules, Actions, and Triggers",
		bit: 7
	},
	{
		name: "Create/Edit Collections",
		bit: 4
	},
	{
		name: "Add/Remove Computers in Organization",
		bit: 5
	},
	{
		name: "Change Build / Edit Organization",
		bit: 6
	}
];